<template>
  <div>
    <b-card class="shadow-none" v-if="type === 1">
      <b-card-header class="document-header document-public-offer" style="">
        <div class="document-header-block" style="">
          <p
            :class="{ 'font-weight-bolder': type === 1 }"
            style=" margin-right: 10px; cursor: pointer; color: #426FF6;"
            @click="changeType(1)"
          >
            Публичная офферта 1
          </p>
          <p class="mobile-hidden">/</p>
          <p
            :class="{ 'font-weight-bolder': type === 2 }"
            style=" margin-left: 10px; cursor: pointer; color: #426FF6;"
            @click="changeType(2)"
          >
            Публичная офферта 2
          </p>
        </div>

        <div
          class="document-header-block"
          style="justify-content: space-between;"
        >
          <b-card-title>
            Публичная офферта 1
          </b-card-title>

          <span>{{$t('docs.revision')}}</span>
        </div>
      </b-card-header>

      <b-card-text class="text-black pl-2 pr-2 pb-2">
        <div v-if="$i18n.locale == 'ru'" class="frame_">
          <!-- контент -->
          контент
        </div>

        <!-- en версия -->
        <div v-else class="frame_">
          <!-- контент -->
          контент en
        </div>
      </b-card-text>
    </b-card>
    <b-card class="shadow-none" v-if="type === 2">
      <b-card-header class="document-header document-public-offer" style="">
        <div class="document-header-block">
          <p
            :class="{ 'font-weight-bolder': type === 1 }"
            style=" margin-right: 10px; cursor: pointer; color: #426FF6;"
            @click="changeType(1)"
          >
            Вкладка 1
          </p>
          <p class="mobile-hidden">/</p>
          <p
            :class="{ 'font-weight-bolder': type === 2 }"
            style=" margin-left: 10px; cursor: pointer; color: #426FF6;"
            @click="changeType(2)"
          >
            Вкладка 2
          </p>
        </div>

        <div
          class="document-header-block"
          style="justify-content: space-between;"
        >
          <b-card-title>
            Публичная офферта 2
          </b-card-title>

          <span>{{$t('docs.revision')}}</span>
        </div>
      </b-card-header>

      <b-card-text class="text-black pl-2 pr-2 pb-2">
        <div v-if="$i18n.locale == 'ru'" class="frame_">
          <!-- контент -->
          контент
        </div>

        <div v-else class="frame_">          
          <!-- контент -->
          контент en
      </div>

      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    const title = this.$t('title-pages.public-offer');
    return {
      title: title,
      titleTemplate: null,
    }
  },
  props: {
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  methods: {
    changeType(type) {
      this.$emit("changeType", { type: type });
    },
  },
  components: {},
  watch: {},
  mounted() {},
};
</script>
