<template>

    
    <b-row>
        
        <b-col md="3">
            <div class="document-cards">
                
                <b-card @click="changeSelectedTab(1)" no-body :bg-variant="selectedTabIndex === 1 ? '' : 'transparent' " :class=" selectedTabIndex === 1 ? 'is-picker' : 'is-picker with-border' ">
                    <b-card-body style="padding: 16px 24px;">
                        <div style="z-index: 100; position: relative;">
                            <p class="font-weight-bolder">{{$t('docs.privacy-policy')}}</p>
                            <span class="text-muted">
                                {{$t('docs.revision')}}
                            </span>
                        </div>
                    </b-card-body>
                </b-card>

                <b-card @click="changeSelectedTab(2)" no-body :bg-variant="selectedTabIndex === 2 ? '' : 'transparent' " :class=" selectedTabIndex === 2 ? 'is-picker' : 'is-picker with-border' ">
                    <b-card-body style="padding: 16px 24px;">
                        <div style="z-index: 100; position: relative;">
                            <p class="font-weight-bolder">{{$t('docs.public-offer')}}</p>
                            <span class="text-muted">
                                {{$t('docs.revision')}}
                            </span>
                        </div>
                    </b-card-body>
                </b-card>
                
            </div>
            
        </b-col>
        <b-col md="8">
            <PrivacyPolicy v-if=" selectedTabIndex === 1" />
            <PublicOffer v-if=" selectedTabIndex === 2" :type="type" @changeType="changeType"/>
        </b-col>
         
    </b-row>
    
</template>

<script>
    
    import PrivacyPolicy from "./contents/privacy-policy"
    import PublicOffer from "./contents/public-offer"
    import User from "@/modules/user/"

    export default {

        data() {
            return {
                selectedTabIndex: 1,
                type: 1,

                params: new URLSearchParams(window.location.search)
            }
        },
        methods: {
            changeSelectedTab( value ) {
                window.scrollTo(0, 0);
                this.selectedTabIndex = value;
            },
            changeType(type) {
                this.type = type.type
            }
        },
        components: {
            PrivacyPolicy,
            PublicOffer
        },
        watch: {

        },
        mounted() {

            if( this.$route.params.type ) {
                if( this.$route.params.type === 'public-offer' ) {
                    if (User.authorized === false) {
                        this.$router.push({name: 'page-public-offer'})
                    } else {
                        this.selectedTabIndex = 2;
                    }
                } else if(this.$route.params.type === 'privacy') {
                    if (User.authorized === false) {
                        this.$router.push({name: 'page-policy'})
                    } else {
                        this.selectedTabIndex = 1;
                    }
                }
            } else {
                if (User.authorized === false) {
                    if (this.params.get('show')) {
                        if (this.params.get('show') == 'public-offer') {
                            this.$router.push({name: 'page-public-offer', params: { type: this.params.get('type')}})
                        }
                    } else {
                        this.$router.push({name: 'page-public-offer'})
                    }
                } 
            }

            if (this.params.get('show')) {
                this.selectedTabIndex = 2;
                if (this.params.get('show') == 'public-offer') {
                    this.type = parseInt(this.params.get('type'))
                }
            }

        }

    }

</script>