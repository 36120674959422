<template>

    <div>
   
    <b-card  class="shadow-none" v-if="language === 'ru'">

        <b-card-header class="document-header" style="">
                <div class="document-header-block">
                    <p class="font-weight-bolder" style=" margin-right: 10px; cursor: pointer; color: #426FF6;">Русский</p>
                    <p class="">/</p>
                    <p style=" margin-left: 10px; cursor: pointer; color: #426FF6" @click="language = 'en'">English</p>

                </div>
                
                <div class="document-header-block" style="justify-content: space-between;">
                    <b-card-title>
                        Политика конфиденциальности
                    </b-card-title>
                    
                    <span>Редакция от 05.01.2023 года</span>
                </div>
            
        </b-card-header>
        <b-card-text class="text-black pl-2 pr-2 pb-2">

            <div class="frame_">
                <!-- контент -->
                контент
            </div>

        </b-card-text>

    </b-card>
    <b-card v-else class="shadow-none">
                
                <b-card-header class="document-header" style="">
                <div class="document-header-block">
                    <p style=" margin-right: 10px; cursor: pointer; color: #426FF6" @click="language = 'ru'">Русский</p>
                    <p class="">/</p>
                    <p class="font-weight-bolder" style=" margin-left: 10px; cursor: pointer; color: #426FF6">English</p>

                </div>
                
                <div class="document-header-block" style="justify-content: space-between;">
                    <b-card-title>
                        Privacy policy
                    </b-card-title>
                    
                    <span>Edition from 05.01.2023</span>
                </div>
            
        </b-card-header>
                
                <b-card-text class="text-black pl-2 pr-2 pb-2">
                    
                    <div class="frame_">
                        <!-- контент -->
                        контент en
                    </div>
                    
                </b-card-text>
                
            </b-card>

        </div>
   
    
</template>

<script>

    export default {
        metaInfo() {
            const title = this.$t('title-pages.privacy-policy');
            return {
            title: title,
            titleTemplate: null,
            }
        },
        data() {
            return {
                language: this.$i18n.locale == 'ru' ? 'ru':'en'
            }
        },
        methods: {

        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>